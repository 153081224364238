import React from "react";
import { Flex } from "shared/components";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import PostCard from "./sublet/post/Card";
import Loader from "shared/components/Loader";
import { useFetchPost } from "shared/hooks/sublet/useFetchPost";

const Sublet = () => {
  const [selectedRegion, setSelectedRegion] = React.useState<string>("다합");
  const { data: posts, isLoading } = useFetchPost();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Loader isLoading={isLoading} />
      <Flex flexDirection="column">
        {!isLoading && posts?.map(d => <PostCard key={d.id} data={d} />)}
      </Flex>
    </Flex>
  );
};

export default Sublet;
